import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  width: 970px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.primaryDarkest};
`

const MenuButtons = styled.menu`
  display: flex;
  margin: 0;
  padding: 0 15px;
`

const MenuButton = styled(NavLink)`
  color: white;
  background: none;
  border: none;
  text-transform: uppercase;
  font: ${(props) => props.theme.text.subtitle};
  padding: 18px 10px;
  outline: none;
  text-decoration: none;
  margin: 0 5px;
  /* font-weight: 600; */

  &:hover {
    background: ${(props) => props.theme.colors.primary};
  }

  &.active {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.tertiary};
  }
`

const MenuButtonLink = styled(Link)`
  color: white;
  background: none;
  border: none;
  text-transform: uppercase;
  font: ${(props) => props.theme.text.subtitle};
  padding: 18px 10px;
  outline: none;
  text-decoration: none;
  margin: 0 5px;
  /* font-weight: 600; */

  &:hover {
    background: ${(props) => props.theme.colors.primary};
  }

  &.active {
    background: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.tertiary};
  }
`

const Menu = () => {
  return (
    <Container>
      <MenuButtons>
        {/* <MenuButton activeClassName="active" exact to="/">
          Home
        </MenuButton> */}
        <MenuButtonLink
          to={{
            pathname: '/',
            state: { refresh: true }
          }}
        >
          Home
        </MenuButtonLink>
        <MenuButton activeClassName="active" to="/men">
          Men
        </MenuButton>
        <MenuButton activeClassName="active" to="/women">
          Women
        </MenuButton>
        <MenuButton activeClassName="active" to="/kids">
          Kids
        </MenuButton>
        <MenuButton activeClassName="active" to="/new">
          New
        </MenuButton>
        <MenuButton activeClassName="active" to="/sale">
          Sale
        </MenuButton>
        <MenuButton activeClassName="active" to="/college">
          College
        </MenuButton>
        <MenuButton activeClassName="active" to="/accessories">
          Accessories
        </MenuButton>
        <MenuButton activeClassName="active" to="/media">
          Media
        </MenuButton>
      </MenuButtons>
    </Container>
  )
}

export default Menu
