import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Airtable from 'airtable'
import { CONFIG } from '../config'
import _ from 'lodash'
import { Headline, Body, Label } from '../components/Text'

const Container = styled.div`
  width: 970px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.white};
`

const PageContainer = styled.div`
  margin: 20px;
  display: flex;
`

const ProductGalleryContainer = styled.main`
  flex: 1 0 auto;
  background: ${(props) => props.theme.colors.primaryDarkest};
  margin-right: 10px;
  padding: 20px;
  text-align: center;
`

const ProductGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
`

const ProductContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  height: 396px;
  background-image: url(${(props) => props.image});
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  &:hover {
    border: 4px solid ${(props) => props.theme.colors.secondary};
    box-sizing: border-box;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  }
`

const ProductName = styled.div`
  position: absolute;
  width: 100%;
  bottom: 10px;
`

const INITIAL_STATE = {
  products: null
}

class MagazinePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...INITIAL_STATE
    }
  }

  componentDidMount = () => {
    this.getProducts()
  }

  getProducts = (brandRecordId) => {
    let base = new Airtable({ apiKey: CONFIG.AIRTABLE_API }).base(
      'app0JnDnIPz8ny1Ku'
    )
    let products = []

    base('Magazines')
      .select({
        sort: [{ field: 'Order', direction: 'asc' }],
        filterByFormula: `AND(Active=1)`
      })
      .eachPage(
        (this.page = (records, fetchNextPage) => {
          records.forEach((record) => {
            products.push(record)
          })
          fetchNextPage()
        }),
        (this.done = (err) => {
          if (err) {
            console.error(err)
            return
          } else {
            this.setState({ products: products })
          }
        })
      )
  }

  render() {
    return (
      <Container>
        <PageContainer>
          <ProductGalleryContainer>
            <Headline color="white" weight="semibold" margin="10px auto 20px">
              Print Ads
            </Headline>
            {this.state.products && (
              <ProductGallery>
                {this.state.products.map((productItem, index) => (
                  <ProductContainer
                    key={index}
                    image={_.get(productItem, 'fields.Image[0].url')}
                  >
                    <ProductName>
                      <Label weight="semibold">{productItem.fields.Name}</Label>
                    </ProductName>
                  </ProductContainer>
                ))}
              </ProductGallery>
            )}
          </ProductGalleryContainer>
        </PageContainer>
      </Container>
    )
  }
}

export default MagazinePage
