const theme = {
  colors: {
    //primary colors
    primaryLightest: '#E6F6FF',
    primary: '#102848',
    primaryDarkest: '#031730',
    //secodnary colors
    secondaryLightest: '#E3F9E5',
    secondary: '#007A3D',
    secondaryDarkest: '#05400A',
    //tertiary colors
    tertiaryLightest: '#FFE3E3',
    tertiary: '#FF2424',
    tertiaryDarkest: '#610316',
    //success colors
    successLightest: '#F8FFED',
    successLighter: '#C1EAC5',
    successLight: '#7BC47F',
    success: '#3F9142',
    successDark: '#2F8132',
    successDarker: '#0E5814',
    successDarkest: '#05400A',
    //warning colors
    warningLightest: '#FFFBEA',
    warningLighter: '#FFF3C4',
    warningLight: '#FADB5F',
    warning: '#F0B429',
    warningDark: '#DE911D',
    warningDarker: '#B44D12',
    warningDarkest: '#8D2B0B',
    //danger colors
    dangerLightest: '#FFE3E3',
    dangerLighter: '#FFBDBD',
    dangerLight: '#F86A6A',
    danger: '#E12D39',
    dangerDark: '#CF1124',
    dangerDarker: '#8A041A',
    dangerDarkest: '#610316',
    //neutral colors
    neutralLightest: '#F0F4F8',
    neutralLighter: '#D9E2EC',
    neutralLight: '#BCCCDC',
    neutral: '#829AB1',
    neutralDark: '#486581',
    neutralDarker: '#243B53',
    neutralDarkest: '#102A43',
    //system colors
    white: '#FFFFFF',
    blackLight: '#3A3A3A',
    blackDark: '#000000'
  },
  text: {
    //header test
    display: '48px/48px Open Sans, sans-serif', //h1
    subdisplay: '32px/32px Open Sans, sans-serif', //h2
    headline: '24px/24px Open Sans, sans-serif', //h3
    title: '20px/20px Open Sans, sans-serif', //h4
    subtitle: '18px Open Sans, sans-serif', //h5
    //paragraph text
    label: '16px Open Sans, sans-serif',
    body: '14px Open Sans, sans-serif',
    caption: '12px Open Sans, sans-serif',
    legal: '11px Open Sans, sans-serif'
  },
  shadows: {
    light: '0px 2px 5px rgba(0, 0, 0, 0.15)',
    dark: '0px 5px 10px rgba(0, 0, 0, 0.30)'
  },
  spacing: {
    units: ['0', '4px', '8px']
  },
  weights: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700
  }
}

export { theme }
