import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Airtable from 'airtable'
import { CONFIG } from '../config'
import _ from 'lodash'
import { Headline, Body, Label } from '../components/Text'
import ProductItem from '../components/ProductItem'

const Container = styled.div`
  width: 970px;
  margin: 0 auto;
  background: ${props => props.theme.colors.white};
`

const PageContainer = styled.div`
  margin: 20px;
  display: flex;
`

const ProductGalleryContainer = styled.main`
  flex: 1 0 auto;
  background: ${props => props.theme.colors.primaryDarkest};
  margin-right: 10px;
  padding: 20px;
  text-align: center;
`

const ProductGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
`

const ProductContainer = styled.div`
  background: ${props => props.theme.colors.white};
  height: 396px;
  background-image: url(${props => props.image});
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  &:hover {
    border: 4px solid ${props => props.theme.colors.secondary};
    box-sizing: border-box;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  }
`

const ProductName = styled.div`
  position: absolute;
  width: 100%;
  bottom: 10px;
`

const SideMenu = styled.aside`
  background: ${props => props.theme.colors.primaryDarkest};
  padding: 20px;
  height: fit-content;
`

const SideMenuList = styled.div`
  display: flex;
  flex-direction: column;

  a:not(:last-child) {
    margin-bottom: 10px;
  }

  a:hover {
    opacity: 0.5;
    transition: all 0.3s ease-out;
  }
`

const SideMenuItem = styled.img`
  width: 128px;
`

const INITIAL_STATE = {
  activeBrand: null,
  brands: null,
  products: null
}

class ProductPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...INITIAL_STATE,
      brands: _.filter(props.location.brands, 'fields.Name')
    }
  }

  componentDidMount = () => {
    this.getBrands(this.props.type)
    this.getProducts(this.props.match.params.id)
  }

  componentWillReceiveProps = updProps => {
    this.getBrands(updProps.type)
  }

  getBrands = type => {
    if (this.state.brands && this.state.brands.length !== 0) {
      return
    }

    let base = new Airtable({ apiKey: CONFIG.AIRTABLE_API }).base(
      'app0JnDnIPz8ny1Ku'
    )
    let brands = []

    base('Brands')
      .select({
        sort: [{ field: 'Order', direction: 'asc' }],
        filterByFormula: `AND(Active=1,Type='${_.startCase(type)}',Name!='')`
      })
      .eachPage(
        (this.page = (records, fetchNextPage) => {
          records.forEach(record => {
            brands.push(record)
          })
          fetchNextPage()
        }),
        (this.done = err => {
          if (err) {
            console.error(err)
            return
          } else {
            this.setState({ brands: brands })
          }
        })
      )
  }

  getProducts = brandRecordId => {
    this.setState({ activeBrand: brandRecordId })

    let base = new Airtable({ apiKey: CONFIG.AIRTABLE_API }).base(
      'app0JnDnIPz8ny1Ku'
    )
    let products = []

    base('Products')
      .select({
        sort: [{ field: 'Order', direction: 'asc' }],
        filterByFormula: `AND(Active=1,brandRecordId='${brandRecordId}')`
      })
      .eachPage(
        (this.page = (records, fetchNextPage) => {
          records.forEach(record => {
            products.push(record)
          })
          fetchNextPage()
        }),
        (this.done = err => {
          if (err) {
            console.error(err)
            return
          } else {
            this.setState({ products: products })
          }
        })
      )
  }

  getActiveBrandName = brandID => {
    const activeBrand = _.find(this.state.brands, ['id', brandID])
    const brandName = activeBrand.fields.Name
    return brandName
  }

  render() {
    return (
      <Container>
        <PageContainer>
          <ProductGalleryContainer>
            {this.state.brands &&
              this.state.brands.length !== 0 &&
              this.state.activeBrand && (
                <Headline
                  color="white"
                  weight="semibold"
                  margin="10px auto 20px"
                >
                  {this.getActiveBrandName(this.state.activeBrand)}
                </Headline>
              )}
            {this.state.products && (
              <ProductGallery>
                {this.state.products.map((productItem, index) => (
                  <ProductItem item={productItem} key={index} />
                ))}
              </ProductGallery>
            )}
          </ProductGalleryContainer>
          <SideMenu>
            {this.state.brands && (
              <SideMenuList>
                {this.state.brands.map((menuItem, index) => (
                  <Link
                    key={index}
                    to={{
                      pathname: `${
                        menuItem.fields.Name
                          ? `/${this.props.type}/${_.get(menuItem, 'id')}`
                          : ''
                      }`
                    }}
                    onClick={e => this.getProducts(_.get(menuItem, 'id'))}
                  >
                    <SideMenuItem
                      src={_.get(menuItem, 'fields.Image[0].url')}
                    />
                  </Link>
                ))}
              </SideMenuList>
            )}
          </SideMenu>
        </PageContainer>
      </Container>
    )
  }
}

export default ProductPage
