import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Caption } from './Text'

const Container = styled.footer`
  display: flex;
  background: ${(props) => props.theme.colors.primaryDarkest};
  margin: auto 0 0;
`

const Content = styled.div`
  display: flex;
  width: 970px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
`

const FooterMenu = styled.menu`
  display: flex;
`

const FooterMenuItem = styled(NavLink)`
  text-decoration: none;
  padding: 5px 10px;
  color: ${(props) => props.theme.colors.neutral};

  &:not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.colors.neutralDarker};
  }

  &:hover {
    color: white;
  }
`

const Footer = () => {
  return (
    <Container>
      <Content>
        <Caption color="neutral">
          Copyright © 2019, Alligator Alley. All Rights Reserved
        </Caption>
        <FooterMenu>
          <FooterMenuItem to="/">
            <Caption>Home</Caption>
          </FooterMenuItem>
          <FooterMenuItem to="/men">
            <Caption>Men</Caption>
          </FooterMenuItem>
          <FooterMenuItem to="/women">
            <Caption>Women</Caption>
          </FooterMenuItem>
          <FooterMenuItem to="/kids">
            <Caption>Kids</Caption>
          </FooterMenuItem>
          <FooterMenuItem to="/college">
            <Caption>College</Caption>
          </FooterMenuItem>
          <FooterMenuItem to="/new">
            <Caption>New</Caption>
          </FooterMenuItem>
          <FooterMenuItem to="/sale">
            <Caption>Sale</Caption>
          </FooterMenuItem>
          <FooterMenuItem to="/accessories">
            <Caption>Accessories</Caption>
          </FooterMenuItem>
          <FooterMenuItem to="/media">
            <Caption>Media</Caption>
          </FooterMenuItem>
        </FooterMenu>
      </Content>
    </Container>
  )
}

export default Footer
