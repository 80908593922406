import React from 'react'
import Lightbox from 'react-image-lightbox'
import styled from 'styled-components'
import _ from 'lodash'
import { Headline, Body, Label, Title } from '../components/Text'

const ProductContainer = styled.button`
  background: ${props => props.theme.colors.white};
  height: 396px;
  background-image: url(${props => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  border: 4px solid ${props => props.theme.colors.white};
  padding: 0;
  outline: none;

  .product-details {
    opacity: 0;
  }

  &:hover {
    border: 4px solid ${props => props.theme.colors.secondary};
    box-sizing: border-box;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);

    .product-details {
      transition: all 0.3s ease-in 0.1s;
      opacity: 1;
    }
  }
`

const ProductDetails = styled.div`
  height: 50px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProductName = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.white};
`

const INITIAL_STATE = {
  isOpen: false
}

class ProductItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = { ...INITIAL_STATE }
  }

  render() {
    return (
      <>
        <ProductContainer
          image={_.get(this.props.item, 'fields.Image[0].url')}
          onClick={() => this.setState({ isOpen: true })}
        >
          {this.props.item.fields.Price && (
            <ProductDetails className="product-details">
              <Title color="white">
                ${this.props.item.fields.Price.toFixed(2)}
              </Title>
            </ProductDetails>
          )}
          <ProductName>
            <Label weight="semibold">{this.props.item.fields.Name}</Label>
          </ProductName>
        </ProductContainer>
        {this.state.isOpen && (
          <Lightbox
            wrapperClassName="lightbox"
            enableZoom={false}
            imagePadding={50}
            imageLoadErrorMessage={'error'}
            imageTitle={this.props.item.fields.Name}
            imageCaption={
              this.props.item.fields.Price
                ? `$${this.props.item.fields.Price.toFixed(2)}`
                : false
            }
            mainSrc={_.get(this.props.item, 'fields.Image[0].url')}
            // nextSrc={images[(photoIndex + 1) % images.length]}
            // prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            // onMovePrevRequest={() =>
            //   this.setState({
            //     photoIndex: (photoIndex + images.length - 1) % images.length,
            //   })
            // }
            // onMoveNextRequest={() =>
            //   this.setState({
            //     photoIndex: (photoIndex + 1) % images.length,
            //   })
            // }
          />
        )}
      </>
    )
  }
}

export default ProductItem
