import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Airtable from 'airtable'
import { CONFIG } from '../config'
import _ from 'lodash'
import { Headline } from '../components/Text'

const Container = styled.div`
  width: 970px;
  margin: 0 auto;
  background: ${props => props.theme.colors.white};
`

const PageContainer = styled.div`
  margin: 20px;
  padding: 10px;
  background: ${props => props.theme.colors.primaryDarkest};
  text-align: center;
`

const BrandsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;

  a:hover {
    opacity: 0.5;
    transition: all 0.3s ease-out;
  }
`

const BrandImage = styled.div`
  background: ${props => props.theme.colors.white};
  height: 116px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const INITIAL_STATE = {
  brands: null,
  isLoading: false
}

class BrandsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  componentDidMount = () => {
    this.getBrands(this.props.type)
  }

  componentWillReceiveProps = updProps => {
    this.getBrands(updProps.type)
  }

  getBrands = type => {
    let base = new Airtable({ apiKey: CONFIG.AIRTABLE_API }).base(
      'app0JnDnIPz8ny1Ku'
    )
    let brands = []

    base('Brands')
      .select({
        sort: [{ field: 'Order', direction: 'asc' }],
        filterByFormula: `AND(Active=1,Type='${_.startCase(type)}')`
      })
      .eachPage(
        (this.page = (records, fetchNextPage) => {
          records.forEach(record => {
            brands.push(record)
          })
          fetchNextPage()
        }),
        (this.done = err => {
          if (err) {
            console.error(err)
            return
          } else {
            this.setState({ brands: brands })
          }
        })
      )
  }

  render() {
    return (
      <Container>
        <PageContainer>
          <Headline color="white" weight="semibold" margin="10px auto 20px">
            {this.props.type}
          </Headline>
          {this.state.brands && (
            <BrandsContainer>
              {this.state.brands.map((brandItem, index) => (
                <Link
                  key={index}
                  to={{
                    pathname: `${
                      brandItem.fields.Name
                        ? `/${this.props.type}/${_.get(brandItem, 'id')}`
                        : ''
                    }`,
                    brands: this.state.brands
                  }}
                >
                  <BrandImage image={_.get(brandItem, 'fields.Image[0].url')} />
                </Link>
              ))}
            </BrandsContainer>
          )}
        </PageContainer>
      </Container>
    )
  }
}

export default withRouter(BrandsPage)
