import React from 'react'
import styled from 'styled-components'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'
import Footer from './components/Footer'
import Header from './components/Header'
import Menu from './components/Menu'
import HomePage from './pages/Home'
import BrandsPage from './pages/Brands'
import ProductPage from './pages/Products'
import SalePage from './pages/Sale'
import NewPage from './pages/New'
import ContactPage from './pages/Contact'
import MediaPage from './pages/Media'
import StoresPage from './pages/Stores'
import MagazinePage from './pages/Magazine'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  background-image: url(${require('./assets/images/bg.jpg')});
  background-repeat: no-repeat;
  background-size: cover;
`

function App() {
  return (
    <Container>
      <Router>
        <Header />
        <Menu />
        <Switch>
          <Route exact path="/" component={HomePage} />

          <Route
            exact
            path="/men"
            render={(props) => <BrandsPage {...props} type="men" />}
          />
          <Route
            path="/men/:id"
            render={(props) => <ProductPage {...props} type="men" />}
          />
          <Route
            exact
            path="/women"
            render={(props) => <BrandsPage {...props} type="women" />}
          />
          <Route
            path="/women/:id"
            render={(props) => <ProductPage {...props} type="women" />}
          />
          <Route
            exact
            path="/kids"
            render={(props) => <BrandsPage {...props} type="kids" />}
          />
          <Route
            path="/kids/:id"
            render={(props) => <ProductPage {...props} type="kids" />}
          />
          <Route path="/new" component={NewPage} />
          <Route path="/sale" component={SalePage} />
          <Route
            exact
            path="/college"
            render={(props) => <BrandsPage {...props} type="college" />}
          />
          <Route
            path="/college/:id"
            render={(props) => <ProductPage {...props} type="college" />}
          />
          <Route
            exact
            path="/accessories"
            render={(props) => <BrandsPage {...props} type="accessories" />}
          />
          <Route
            path="/accessories/:id"
            render={(props) => <ProductPage {...props} type="accessories" />}
          />
          <Route exact path="/media" component={MediaPage} />
          <Route path="/media/stores" component={StoresPage} />
          <Route path="/media/magazine" component={MagazinePage} />
          <Redirect to="/" />
        </Switch>
        <Footer />
      </Router>
    </Container>
  )
}

export default App
