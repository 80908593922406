import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Body, Title } from '../components/Text'
import ReactPlayer from 'react-player'

const Container = styled.div`
  width: 970px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.white};
`

const PageContainer = styled.div`
  margin: 20px;
  padding: 10px;
  background: ${(props) => props.theme.colors.primaryDarkest};
  display: flex;
`

const LeftContent = styled.div`
  width: 50%;
  a {
    color: white;
  }
`
const RightContent = styled.div`
  width: 50%;
  position: relative;
  /* padding-top: 56.25%; Player ratio: 100 / (1280 / 720) */

  .react-player {
    /* position: absolute; */
    /* top: 0;
    left: 0; */
    height: auto !important;
  }
`

const StyledPlayer = styled.video`
  width: 100%;
  outline: none;
`

const MediaPage = () => {
  return (
    <Container>
      <PageContainer>
        <LeftContent>
          <Title color="white" margin="0 0 40px">
            Photo Gallery
          </Title>
          <Link to="/media/stores">
            <Body>Take a look at our stores</Body>
          </Link>
          <Link to="/media/magazine">
            <Body>Alligator Alley print ads</Body>
          </Link>
        </LeftContent>
        <RightContent>
          <StyledPlayer
            controls
            poster={require('../assets/images/aa-placeholder.jpg')}
          >
            <source src={require('../assets/videos/aa.mp4')} type="video/mp4" />
          </StyledPlayer>
          {/* <ReactPlayer
            className="react-player"
            url={require('../assets/videos/aa.mp4')}
            light={require('../assets/images/aa-placeholder.png')}
            controls
            volume={0.5}
            width="100%"
          /> */}
        </RightContent>
      </PageContainer>
    </Container>
  )
}

export default MediaPage
