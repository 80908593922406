import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Airtable from 'airtable'
import { CONFIG } from '../config'
import { Body } from '../components/Text'
import _ from 'lodash'
import loadjs from 'loadjs'

const Container = styled.div`
  width: 970px;
  margin: 0 auto;
`

const Hero = styled.div`
  background: ${(props) => props.theme.colors.white};
  margin-bottom: 30px;
  padding: 20px;
`

const LogoImage = styled.img`
  width: 100%;
  margin-bottom: 20px;
`

const Stores = styled.div`
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
`

const StoreImageContainer = styled.div`
  width: 50%;
  margin: 0 10px;
`

const StoreImage = styled.img`
  width: 100%;
`

const Content = styled.div`
  background: white;
  display: flex;
  margin-bottom: 30px;
  padding: 20px;
`

const FeaturedBrandsContainer = styled.div`
  width: 50%;
  background: ${(props) => props.theme.colors.primaryDarkest};
  padding: 10px;
  height: fit-content;

  &:first-child {
    margin-right: 20px;
  }
`

const SignUpContainer = styled.div`
  width: 50%;

  &:first-child {
    margin-right: 20px;
  }
`

const FeaturedBrands = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  a:hover {
    opacity: 0.5;
    transition: all 0.3s ease-out;
  }
`

const BrandImage = styled.div`
  background: ${(props) => props.theme.colors.white};
  height: 66px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const ConstantContact = styled.div`
  h2,
  p,
  label,
  .g-recaptcha {
    display: none !important;
  }

  .ctct-form-defaults {
    padding: 20px 0 0 !important;
  }
`

const INITIAL_STATE = {
  featuredBrands: null
}

class HomePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...INITIAL_STATE
    }
  }

  componentWillMount = () => {
    // this.loadScript(
    //   '//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js'
    // )
  }

  // loadScript = function(src) {
  //   var tag = document.createElement('script')
  //   tag.async = false
  //   tag.src = src
  //   console.log(document.getElementsByTagName('body'), 'body')
  //   var body = document.getElementsByTagName('body')
  //   body[0].appendChild(tag)
  // }

  // loadCC = () => {
  //   loadjs(
  //     [
  //       '//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js'
  //     ],
  //     {
  //       id: 'signupScript',
  //       async: true,
  //       defer: true
  //     }
  //   )
  // }

  componentDidMount = () => {
    let base = new Airtable({ apiKey: CONFIG.AIRTABLE_API }).base(
      'app0JnDnIPz8ny1Ku'
    )
    let featuredBrands = []

    base('Brands')
      .select({
        sort: [{ field: 'Order', direction: 'asc' }],
        filterByFormula: 'AND(Active=1,Featured=1)'
      })
      .eachPage(
        (this.page = (records, fetchNextPage) => {
          records.forEach((record) => {
            featuredBrands.push(record)
          })
          fetchNextPage()
        }),
        (this.done = (err) => {
          if (err) {
            console.error(err)
            return
          } else {
            this.setState({ featuredBrands: featuredBrands })
          }
        })
      )

    if (this.props.location.state && this.props.location.state.refresh) {
      this.props.history.replace('', null)
      window.location.reload()
    }
  }

  render() {
    return (
      <Container>
        <Hero>
          <LogoImage src={require('../assets/images/logo-colored.png')} />
          <Stores>
            <StoreImageContainer>
              <StoreImage
                src={require('../assets/images/roanoke-storefront.jpg')}
              />
            </StoreImageContainer>
            <StoreImageContainer>
              <StoreImage
                src={require('../assets/images/roanoke-inside-christmas.png')}
              />
            </StoreImageContainer>
          </Stores>
        </Hero>
        <Content>
          <FeaturedBrandsContainer>
            {this.state.featuredBrands && (
              <FeaturedBrands>
                {this.state.featuredBrands.map((brandItem, index) => (
                  <Link
                    key={index}
                    to={{
                      pathname: `${
                        brandItem.fields.Name
                          ? `men/${_.get(brandItem, 'id')}`
                          : ''
                      }`,
                      brands: this.state.brands
                    }}
                  >
                    <BrandImage
                      image={_.get(brandItem, 'fields.Image[0].url')}
                    />
                  </Link>
                ))}
              </FeaturedBrands>
            )}
          </FeaturedBrandsContainer>
          <SignUpContainer>
            <Body weight="semibold">
              Sign up for updates! Get news from Alligator Alley in your inbox.
            </Body>
            {/* <!-- Begin Constant Contact Inline Form Code --> */}
            <ConstantContact
              className="ctct-inline-form"
              data-form-id="b9906982-1e53-4415-80d6-cfea46f94eb8"
            ></ConstantContact>
            {/* <!-- End Constant Contact Inline Form Code --> */}
          </SignUpContainer>
        </Content>
      </Container>
    )
  }
}

export default HomePage
