import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Airtable from 'airtable'
import { CONFIG } from '../config'
import _ from 'lodash'
import { Headline, Body, Label } from '../components/Text'

const Container = styled.div`
  width: 970px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.white};
`

const PageContainer = styled.div`
  margin: 20px;
  display: flex;
`

const StoreGalleryContainer = styled.main`
  background: ${(props) => props.theme.colors.primaryDarkest};
  padding: 20px;
  text-align: center;
`

const StoreGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`

const StoreContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  position: relative;
  padding: 20px;

  &:hover {
    border: 4px solid ${(props) => props.theme.colors.secondary};
    box-sizing: border-box;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.7);
  }
`

const StoreImage = styled.img`
  width: 90%;
`

const StoresPage = () => {
  return (
    <Container>
      <PageContainer>
        <StoreGalleryContainer>
          <Headline color="white" weight="semibold" margin="10px auto 20px">
            Stores
          </Headline>
          <StoreGallery>
            <StoreContainer>
              <StoreImage
                src={require('../assets/images/roanoke-storefront.jpg')}
              />
              <Label weight="semibold" margin="10px 0 0">
                Roanoke Storefront
              </Label>
            </StoreContainer>
            <StoreContainer>
              <StoreImage
                src={require('../assets/images/roanoke-inside-1.jpg')}
              />
              <Label weight="semibold" margin="10px 0 0">
                Roanoke Inside
              </Label>
            </StoreContainer>
            <StoreContainer>
              <StoreImage
                src={require('../assets/images/roanoke-inside-2.jpg')}
              />
              <Label weight="semibold" margin="10px 0 0">
                Roanoke Inside
              </Label>
            </StoreContainer>
            <StoreContainer>
              <StoreImage
                src={require('../assets/images/roanoke-inside-barbour.jpeg')}
              />
              <Label weight="semibold" margin="10px 0 0">
                Roanoke Inside
              </Label>
            </StoreContainer>
          </StoreGallery>
        </StoreGalleryContainer>
      </PageContainer>
    </Container>
  )
}

export default StoresPage
