import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import Airtable from 'airtable'
import { CONFIG } from '../config'
import _ from 'lodash'
import { Headline, Label, Title } from '../components/Text'

const Container = styled.div`
  width: 970px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.white};
`

const Stores = styled.div`
  display: flex;
`

const StoreContainer = styled.div`
  width: 50%;
  padding: 20px;

  &:first-child {
    padding-right: 0;
  }
`

const StoreImage = styled.img`
  margin-top: 20px;
  width: 100%;
`

const ContactPage = () => {
  return (
    <Container>
      <Stores>
        <StoreContainer>
          <Title weight="semibold" color="tertiary" margin="0 0 10px">
            Roanoke Store
          </Title>
          <Label color="secondary">Monday - Friday: 11:00 - 5:00 PM</Label>
          <Label color="secondary">Saturday: 11:00 - 4:00 PM</Label>
          <Label color="secondary">Sunday: Varies, please call</Label>
          <StoreImage src={require('../assets/images/roanoke-map.jpg')} />
        </StoreContainer>
      </Stores>
    </Container>
  )
}

export default ContactPage
