import React from 'react'
import styled from 'styled-components'
import Airtable from 'airtable'
import { CONFIG } from '../config'
import _ from 'lodash'
import { Headline, Body, Label } from '../components/Text'
import ProductItem from '../components/ProductItem'

const Container = styled.div`
  width: 970px;
  margin: 0 auto;
  background: ${props => props.theme.colors.white};
`

const PageContainer = styled.div`
  margin: 20px;
  display: flex;
`

const ProductGalleryContainer = styled.main`
  flex: 1 0 auto;
  background: ${props => props.theme.colors.primaryDarkest};
  margin-right: 10px;
  padding: 20px;
  text-align: center;
`

const ProductGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`

const INITIAL_STATE = {
  products: null,
  photoIndex: 0,
  isOpen: false
}

class NewPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      ...INITIAL_STATE
    }
  }

  componentDidMount = () => {
    this.getProducts()
  }

  getProducts = () => {
    let base = new Airtable({ apiKey: CONFIG.AIRTABLE_API }).base(
      'app0JnDnIPz8ny1Ku'
    )
    let products = []

    base('New')
      .select({
        sort: [{ field: 'Order', direction: 'asc' }],
        filterByFormula: `AND(Active=1)`
      })
      .eachPage(
        (this.page = (records, fetchNextPage) => {
          records.forEach(record => {
            products.push(record)
          })
          fetchNextPage()
        }),
        (this.done = err => {
          if (err) {
            console.error(err)
            return
          } else {
            this.setState({ products: products })
          }
        })
      )
  }

  render() {
    return (
      <Container>
        <PageContainer>
          <ProductGalleryContainer>
            <Headline color="white" weight="semibold" margin="10px auto 20px">
              New Items
            </Headline>
            {this.state.products && (
              <ProductGallery>
                {this.state.products.map((productItem, index) => (
                  <ProductItem item={productItem} key={index} />
                ))}
              </ProductGallery>
            )}
          </ProductGalleryContainer>
        </PageContainer>
      </Container>
    )
  }
}

export default NewPage
