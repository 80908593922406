import React from 'react'
import styled from 'styled-components'
import { Body } from './Text'

const Container = styled.header`
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  width: 970px;
  margin: 0 auto;
  background: ${(props) => props.theme.colors.white};
  align-items: center;
`

const LogoContainer = styled.div`
  height: 152px;
  padding-left: 20px;
`

const LogoImage = styled.img`
  width: 290px;
  margin: 20px 0;
`

const Header = () => {
  return (
    <Container>
      <LogoContainer>
        <LogoImage src={require('../assets/images/logo-white.png')} />
      </LogoContainer>
    </Container>
  )
}

export default Header
