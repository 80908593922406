import styled from 'styled-components'

export const Display = styled.h1`
  font: ${props => props.theme.text.display};
  font-weight: ${props =>
    props.weight
      ? props.theme.weights[props.weight]
      : props.theme.weights.regular};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.black};
  margin: ${props => (props.margin ? props.margin : 0)};
`

export const Subdisplay = styled.h2`
  font: ${props => props.theme.text.subdisplay};
  font-weight: ${props =>
    props.weight
      ? props.theme.weights[props.weight]
      : props.theme.weights.regular};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.black};
  margin: ${props => (props.margin ? props.margin : 0)};
`

export const Headline = styled.h3`
  font: ${props => props.theme.text.headline};
  font-weight: ${props =>
    props.weight
      ? props.theme.weights[props.weight]
      : props.theme.weights.regular};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.black};
  margin: ${props => (props.margin ? props.margin : 0)};
  text-transform: uppercase;
`

export const Title = styled.h4`
  font: ${props => props.theme.text.title};
  font-weight: ${props =>
    props.weight
      ? props.theme.weights[props.weight]
      : props.theme.weights.regular};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.black};
  margin: ${props => (props.margin ? props.margin : 0)};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`

export const Subtitle = styled.h5`
  font: ${props => props.theme.text.subtitle};
  font-weight: ${props =>
    props.weight
      ? props.theme.weights[props.weight]
      : props.theme.weights.regular};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.black};
  margin: ${props => (props.margin ? props.margin : 0)};
`

export const Label = styled.p`
  font: ${props => props.theme.text.label};
  font-weight: ${props =>
    props.weight
      ? props.theme.weights[props.weight]
      : props.theme.weights.regular};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.black};
  margin: ${props => (props.margin ? props.margin : 0)};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`

export const Body = styled.p`
  font: ${props => props.theme.text.body};
  font-weight: ${props =>
    props.weight
      ? props.theme.weights[props.weight]
      : props.theme.weights.regular};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.black};
  margin: ${props => (props.margin ? props.margin : 0)};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
`

export const Caption = styled.p`
  font: ${props => props.theme.text.caption};
  font-weight: ${props =>
    props.weight
      ? props.theme.weights[props.weight]
      : props.theme.weights.regular};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.black};
  margin: ${props => (props.margin ? props.margin : 0)};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`

export const Legal = styled.p`
  font: ${props => props.theme.text.legal};
  font-weight: ${props =>
    props.weight
      ? props.theme.weights[props.weight]
      : props.theme.weights.regular};
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.black};
  margin: ${props => (props.margin ? props.margin : 0)};
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'none')};
`
